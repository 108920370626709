<template>
  <div>
    <!-- 主要发明人组件 -->
    <Echart :options="options" :id="id" height="50rem" width="90%" />
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  name: "inventorMap",
  components: {
    Echart
  },
  props: {
    getData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      options: {},
      id: "echarts"
    };
  },
  mounted() {},
  methods: {},
  watch: {
    getData: {
      handler: function(val) {
        /* 根据 inventorKeyValueList 获取 发明人姓名数组及对应数量数组 satrt*/
        let peopleList = [];
        let countList = [];
        for (let i = 0; i < val.inventorKeyValueList.length; i++) {
          const obj = {
            kw: "in:",
            value: val.inventorKeyValueList[i].value,
            company: val.compareMap[1]
          };
          peopleList.push(val.inventorKeyValueList[i].key);
          countList.push(obj);
        }
        /* 根据 inventorKeyValueList 获取 发明人姓名数组及对象数量数组 end*/
        this.options = {
          title: {
            text: "主要发明人",
            x: "center"
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            top: "10%",
            containLabel: true
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: "category",
            data: peopleList
          },
          series: [
            {
              name: "专利量",
              type: "bar",
              itemStyle: {
                color: "#5470c6"
              },
              data: countList
            }
          ]
        };
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
