<template>
  <div>
    <!-- 专利法律状态及专利类型组件-->
    <Echart :options="options" :id="id" height="50rem" width="90%" />
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  name: "legalMapAndTypeMap",
  components: {
    Echart
  },
  props: {
    getData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      options: {},
      id: "echarts"
    };
  },
  mounted() {},
  methods: {},
  watch: {
    getData: {
      handler: function(val) {
        let legalData = [];
        let typeData = [];
        /* 处理 legalMap 数据为 Echarts 所用的 start*/
        for (let key in val.legalMap) {
          let obj = {
            name: key,
            value: val.legalMap[key],
            kw: "ls:",
            company: val.compareMap[1]
          };
          legalData.push(obj);
        }
        /* 处理 legalMap 数据为 Echarts 所用的 end*/

        /* 处理 typeMap 数据为 Echarts 所用的 start*/
        for (let key in val.typeMap) {
          let obj = {};
          obj.name = key;
          obj.value = val.typeMap[key];
          obj.kw = "type:";
          obj.company = val.compareMap[1];
          typeData.push(obj);
        }
        /* 处理 typeMap 数据为 Echarts 所用的 end*/
        this.options = {
          title: {
            text: "专利类型分布",
            left: "center",
            y: 40
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          grid: {
            bottom: 10
          },
          toolbox: {
            show: true,
            feature: {
              mark: {
                show: true
              },
              dataView: {
                show: false,
                readOnly: false
              },
              restore: {
                show: false
              },
              saveAsImage: {
                show: false
              }
            }
          },
          series: [
            {
              name: "法律状态",
              type: "pie",
              radius: [20, 140],
              center: ["25%", "50%"],
              roseType: "area",
              itemStyle: {
                borderRadius: 5
              },
              label: {
                show: true
              },
              emphasis: {
                label: {
                  show: true
                }
              },
              data: legalData
            },
            {
              name: "专利类型",
              type: "pie",
              radius: [20, 140],
              center: ["75%", "50%"],
              roseType: "area",
              itemStyle: {
                borderRadius: 5
              },
              data: typeData
            }
          ]
        };
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
