<template>
  <section class="row">
    <div class="col-12">
        <q-form style="background: #ffffff; border-radius: 4px;" @submit="onSubmit">
          <div class="justify-around content-around">
            <q-input
              class="q-ma-md"
              v-model="keyword"
              placeholder="请输入您要查询的关键字"
              outlined
              dense
            >
              <template v-slot:prepend>
                <q-icon name="search" />
              </template>
              <template v-slot:append>
                <q-btn
                  class="full-height"
                  label="搜索"
                  color="slrc-primary"
                  style="margin-right: -12px"
                  @click="onSubmit"
                  unelevated
                >
                </q-btn>
              </template>
            </q-input>
          </div>
        </q-form>
      <div
        class="q-pa-md"
        v-if="show"
        style="background-color: #f0f3f5; padding: 0; margin-top: 100px;"
      >
        <div class="q-gutter-y-md">
          <q-tab-panels
            v-model="panel"
            class="shadow-2 rounded-borders"
            animated
            style="background-color: #f0f3f5"
          >
            <q-tab-panel
              name="tables"
              style="display: flex; flex-direction: column; align-items: center"
            >
              <q-card
                style="padding-top: 30px"
                class="cardOption"
                v-show="isHaveData"
              >
                <legal-map-and-type-map
                  :getData="getData"
                  class="echartsStyle"
                ></legal-map-and-type-map>
                <div class="tableTitle">专利分布情况</div>
                <div class="row">
                  <div class="col-6">
                    <q-markup-table separator="vertical">
                      <thead>
                        <tr>
                          <th class="text-left">法律状态</th>
                          <th class="text-left">专利数量</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(val, key, i) in enterprisePortrait.legalMap"
                          :key="i"
                        >
                          <td class="text-left">{{ key }}</td>
                          <td class="text-left">{{ val }}</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                  <div class="col-6">
                    <q-markup-table separator="vertical">
                      <thead>
                        <tr>
                          <th class="text-left">专利类型</th>
                          <th class="text-left">专利数量</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(val, key, i) in enterprisePortrait.typeMap"
                          :key="i"
                        >
                          <td class="text-left">{{ key }}</td>
                          <td class="text-left">{{ val }}</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                </div>
              </q-card>
              <q-card
                style="margin-top: 100px; padding-top: 100px"
                class="cardOption"
                v-show="isHaveData"
              >
                <year-map :getData="getData" class="echartsStyle"></year-map>
                <div class="tableTitle">专利申请趋势</div>
                <div class="row" style="display: block">
                  <q-markup-table separator="vertical">
                    <thead>
                      <tr>
                        <th class="text-left">日期</th>
                        <th
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.applicationYearMap"
                          :key="i"
                        >
                          {{ key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">专利量</td>
                        <td
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.applicationYearMap"
                          :key="i"
                        >
                          {{ val }}
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                  <div class="tableTitle">专利公开趋势</div>
                  <q-markup-table>
                    <thead>
                      <tr>
                        <th class="text-left">日期</th>
                        <th
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.publicationYearMap"
                          :key="i"
                        >
                          {{ key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">专利量</td>
                        <td
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.publicationYearMap"
                          :key="i"
                        >
                          {{ val }}
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                  <div class="tableTitle">专利授权趋势</div>
                  <q-markup-table>
                    <thead>
                      <tr>
                        <th class="text-left">日期</th>
                        <th
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.grantYearMap"
                          :key="i"
                        >
                          {{ key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">专利量</td>
                        <td
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.grantYearMap"
                          :key="i"
                        >
                          {{ val }}
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </q-card>
              <q-card
                v-show="isHaveData"
                style="margin-top: 100px; padding-top: 100px"
                class="cardOption"
              >
                <inventor-map
                  :getData="getData"
                  class="echartsStyle"
                ></inventor-map>
                <div class="tableTitle">主要发明人</div>
                <div class="row" style="display: block">
                  <q-markup-table>
                    <thead>
                      <tr>
                        <th class="text-left">发明人</th>
                        <th
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.inventorKeyValueList"
                          :key="i"
                        >
                          {{ val.key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">专利量</td>
                        <td
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.inventorKeyValueList"
                          :key="i"
                        >
                          {{ val.value }}
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </q-card>
              <q-card
                style="margin-top: 100px"
                class="cardOption"
                v-show="isHaveData"
              >
                <area-map :getData="getData" class="echartsStyle"></area-map>
                <div class="tableTitle">专利区域分布</div>
                <div class="row" style="display: block">
                  <q-markup-table separator="vertical">
                    <thead>
                      <tr>
                        <th class="text-left">区域</th>
                        <th
                          class="text-left"
                          v-for="(val, key, i) in enterprisePortrait.areaMap"
                          :key="i"
                        >
                          {{ key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">专利量</td>
                        <td
                          class="text-left"
                          v-for="(val, key, i) in enterprisePortrait.areaMap"
                          :key="i"
                        >
                          {{ val }}
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </q-card>
              <q-card
                v-show="isHaveData"
                style="margin-top: 100px; padding-top: 100px"
                class="cardOption"
              >
                <ipc-map :getData="getData" class="echartsStyle"></ipc-map>
                <div class="tableTitle">专利技术领域分布</div>
                <div class="row" style="display: block">
                  <q-markup-table>
                    <thead>
                      <tr>
                        <th class="text-left">IPC分类（小类）</th>
                        <th
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.ipcKeyValueList"
                          :key="i"
                        >
                          {{ val.key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">专利量</td>
                        <td
                          class="text-left"
                          v-for="(
                            val, key, i
                          ) in enterprisePortrait.ipcKeyValueList"
                          :key="i"
                        >
                          {{ val.value }}
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </q-card>
              <q-card
                v-show="isHaveData"
                style="margin-top: 100px; padding-top: 20px"
                class="cardOption"
              >
                <div class="tableTitle">主要技术领域的主要竞争对手</div>
                <div class="row" style="display: block">
                  <q-markup-table separator="vertical">
                    <thead>
                      <tr>
                        <th class="text-left">排序号</th>
                        <th class="text-left">竞争对手</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(val, key, i) in enterprisePortrait.compareMap"
                        :key="i"
                        ref="tableItem"
                      >
                        <td class="text-left" ref="bottomTableItemKey">
                          {{ i + 1 }}
                        </td>
                        <td class="text-left" ref="bottomTableItemValue">
                          {{ val }}
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                </div>
              </q-card>
              <q-card v-show="!isHaveData" class="cardOption" id="emptyData">
                <img src="../../public/imgs/empty.png" alt="" />
                <div>暂无数据</div>
              </q-card>
            </q-tab-panel>

            <q-tab-panel name="charts"></q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </div>
    <div v-show="show" class="clickmore">
      <span
        style="cursor: pointer"
        @click="
          () => {
            show = !show;
          }
        "
      >
        返回上一级
      </span>
    </div>
    <div
      v-show="!show"
      class="row wrap justify-between content-between q-gutter-y-md"
      style="margin-top: 86px;"
    >
      <q-card class="a_company" v-for="(item, i) in company" :key="i">
        <a @click="portrait(item.name)">
          <span class="db company_title" v-text="item.title"></span>
          <span class="db font1" v-text="item.name"></span>
          <span class="db font2">
            {{ item.address + " " + "/" + " " + item.industry }}
          </span>
        </a>
      </q-card>
      <div class="clickmore">
        <span
          style="cursor: pointer"
          @click="getCompanyList(page++)"
          v-text="more"
        ></span>
      </div>
    </div>
  </section>
</template>

<script>
// import PortraitChart from "../components/PortraitChart.vue";
import legalMapAndTypeMap from "../components/echarts/legalMapAndTypeMap/index.vue";
import yearMap from "../components/echarts/yearMap/index.vue";
import inventorMap from "../components/echarts/inventorMap/index.vue";
import areaMap from "../components/echarts/areaMap/index.vue";
import ipcMap from "../components/echarts/ipcMap/index.vue";
// import { delete } from 'vue/types/umd';
import { getCompany } from "../api/get-json.js";

export default {
  name: "Portrait",
  components: {
    legalMapAndTypeMap,
    yearMap,
    inventorMap,
    areaMap,
    ipcMap,
  },
  data() {
    return {
      more: "点击查看更多",
      page: 1,
      company: [],
      chart: null,
      panel: "tables",
      show: false,
      keyword: "",
      enterprisePortrait: "",
      getData: "",
      isHaveData: true,
    };
  },
  created() {
    this.getCompanyList(this.page);
  },
  mounted() {
    this.getUrlParam("links");
    this.$nextTick(() => {
      this.initEcharts();
    });
  },
  methods: {
    getCompanyList(page) {
      if (page === 1) {
        getCompany(page).then((res) => {
          this.company = res;
        });
      } else if (page < 10) {
        getCompany(page).then((res) => {
          res.forEach((elem) => {
            this.company.push(elem);
          });
        });
      } else {
        this.more = "没有更多数据了";
      }
    },
    initEcharts() {
      this.chart = this.$echarts.init(document.getElementById("echarts"));
    },
    getPortrait() {
      if (this.keyword) {
        this.$q.loading.show({
          message:
            '正在画像<br/><span class="text-orange text-weight-bold">请稍后...</span>'
        });
        this.$http
          .get(
            process.env.VUE_APP_API_BASE_URL + "/a/portrait?en=" + this.keyword
          )
          .then(response => {
            this.$q.loading.hide();
            if (response.data.code === 200) {
              const result = response.data.enterprisePortrait;
              //判断有无数据
              if (!result.areaMap["中国"]) {
                this.isHaveData = false;
              } else {
                this.isHaveData = true;
              }
              // 数据处理
              this.getData = result;
              this.enterprisePortrait = result;
              // delete this.enterprisePortrait.compareMap[1];
              this.show = true;
            } else {
              this.$q.notify({
                message:response.data.msg, 
                type:'warning', 
                position:'top'  

              })
            }
          });
      }
    },
    onSubmit() {
      this.getPortrait();
    },
    portrait(keyword) {
      this.keyword = keyword;
      this.getPortrait();
    },
    getUrlParam(args) {
      // 构造一个含有目标参数的正则表达式对象
      var reg = new RegExp("(^|&)" + args + "=([^&]*)(&|$)");
      // 匹配目标参数
      var r = window.location.hash.substr(11).match(reg);
      if (r != null) {
        const regex = /<\/?.+?\/?>/gm;
        const substr = "";
        const result = decodeURI(r[2]).replace(regex, substr);
        this.keyword = result;
        this.getPortrait();
      }
    },
  },
};
</script>

<style scoped>
label.q-ma-md {
  margin: 0px;
}
a:hover {
  color: #C61C1C;
}
.a_company {
  width: 285px;
  height: 234px;
  border: 1px solid #fff;
}
.a_company:hover {
  box-shadow: 0 0 0 2px rgb(192 28 28 / 20%);
  border-color: #C61C1C;
  transition: all 0.3s;
  cursor: pointer;
}
.company_title {
  text-align: center;
  font-size: 20px;
  height: 92px;
  font-weight: 700;
  line-height: 24px;
  padding: 38px 10px 0 10px;
}
.db {
  display: block;
}
.font1 {
  font-size: 14px;
  color: #999;
  text-align: center;
  height: 32px;
  line-height: 24px;
}
.font2 {
  font-size: 14px;
  color: #666;
  text-align: center;
  height: 48px;
  line-height: 24px;
}
.echartsStyle {
  /* border: 1px solid rgba(0,0,0,0.5); */
  /* margin: 100px; */
  /* margin-bottom: 0;	 */
  display: flex;
  justify-content: center;
}
.tableTitle {
  margin: 20px;
  font-weight: bold;
}
.cardOption {
  width: 80%;
}
/* .q-table tbody td {
  font-size: inherit;
} */
#fixedTest {
  font-weight: bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.fixedChoose {
  /* display: block; */
  margin-left: 5.625rem;
  font-size: 1rem;
  line-height: 2.5rem;
  cursor: pointer;
}
.fixedChoose:hover {
  color: #0000ff;
}
#emptyData {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15% 100px;
  font-size: 15px;
}
#emptyData img {
  width: 8rem;
  height: 8rem;
}
.clickmore {
  width: 100%;
  height: 48px;
  padding-top: 45px;
  padding-bottom: 90px;
  font-size: 16px;
  text-align: center;
  color: #999999;
}
</style>
