import { render, staticRenderFns } from "./Portrait.vue?vue&type=template&id=052e1435&scoped=true"
import script from "./Portrait.vue?vue&type=script&lang=js"
export * from "./Portrait.vue?vue&type=script&lang=js"
import style0 from "./Portrait.vue?vue&type=style&index=0&id=052e1435&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "052e1435",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QInput,QIcon,QBtn,QTabPanels,QTabPanel,QCard,QMarkupTable,QTable});
