<template>
  <div :id="id" :class="className" style="height: 40rem; width: 100%;" />
</template>

<script>
import resizeMixins from "@/utils/resizeMixins";

export default {
  name: "echart",
  mixins: [resizeMixins],
  props: {
    id: {
      type: String,
      default: "chart"
    },
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "100%"
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    options: {
      handler(options) {
        // 设置 true 清空 echart 缓存
        this.chart.setOption(options, true);
      },
      deep: true
    }
  },
  mounted() {
    const { initChart, whenChartClick } = this;
    initChart();
    this.chart.on("click", whenChartClick);
  },
  methods: {
    initChart() {
      // 初始化echart
      // this.resizeMyChartContainer()
      this.chart = this.$echarts.init(this.$el);
      this.chart.setOption(this.options, true);
      // 在所有的组件中都可以直接使用 this.$echartsResize()
      this.$echartsResize(this.chart);
    },
    whenChartClick(params) {
      const st = params.seriesType;
      const pkw = params.data.kw;
      const pn = params.name;
      const pc = params.data.company;
      const condition = pkw && pn && pc;
      if ((st === "pie" || st === "bar") && condition) {
        console.log("chart is click: ", params);
        const url = "https://r.xjip.info/#/search?links=";
        const c = url + pkw + pn + " " + pc;
        window.open(c, "_blank");
      }
    }
  }
};
</script>
