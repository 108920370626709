<template>
  <div>
    <!-- 专利技术领域分布组件 -->
    <Echart :options="options" :id="id" height="40rem" width="90%" />
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  name: "ipcMap",
  components: {
    Echart
  },
  props: {
    getData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      options: {},
      id: "echarts"
    };
  },
  mounted() {},
  methods: {},
  watch: {
    getData: {
      handler: function(val) {
        /* 根据 ipcKeyValueList 数据获取专利技术领域分布数组 start*/
        let ipcData = [];
        let countData = [];
        for (let i = 0; i < val.ipcKeyValueList.length; i++) {
          let obj = {
            kw: "ipc:",
            value: val.ipcKeyValueList[i].value,
            company: val.compareMap[1]
          };
          ipcData.push(val.ipcKeyValueList[i].key);
          // countData.push(val.ipcKeyValueList[i].value);
          countData.push(obj);
        }
        /* 根据 ipcKeyValueList 数据获取专利技术领域分布数组 end*/
        this.options = {
          title: {
            text: "专利技术领域分布",
            x: "center"
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          xAxis: {
            type: "category",
            data: ipcData
          },
          yAxis: {
            type: "value"
          },
          series: [
            {
              data: countData,
              type: "bar",
              showBackground: true,
              backgroundStyle: {
                color: "rgba(180, 180, 180, 0.2)"
              }
            }
          ]
        };
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
