<template>
  <div>
    <!-- 专利区域分布组件 -->
    <Echart :options="options" :id="id" height="50rem" width="100%" />
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  name: "areaMap",
  components: {
    Echart
  },
  props: {
    getData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      options: {},
      id: "echarts"
    };
  },
  mounted() {},
  methods: {},
  watch: {
    getData: {
      handler: function(val) {
        /* 根据areaMap数据获取专利区域分布地区及数量数组 start*/
        let data = [];
        for (let key in val.areaMap) {
          let obj = {};
          obj.value = val.areaMap[key];
          obj.name = key;
          data.push(obj);
        }
        /* 根据areaMap数据获取专利区域分布地区及数量数组 end*/
        this.options = {
          title: {
            text: "专利区域分布",
            x: "center",
            y: 40
          },
          tooltip: {
            trigger: "item"
          },
          series: [
            {
              name: "访问来源",
              type: "pie",
              radius: "50%",
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }
          ]
        };
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
