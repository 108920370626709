<template>
  <div>
    <!-- 专利申请趋势、公开趋势、授权趋势组件 -->
    <Echart :options="options" :id="id" height="50rem" width="90%" />
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  name: "yearMap",
  components: {
    Echart
  },
  props: {
    getData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      options: {},
      id: "echarts"
    };
  },
  mounted() {},
  methods: {},
  watch: {
    getData: {
      handler: function(val) {
        /* 根据applicationYearMap数据获取申请趋势每年数量 start*/
        let applicationData = [];
        for (let key in val.applicationYearMap) {
          applicationData.push(val.applicationYearMap[key]);
        }
        /* 根据applicationYearMap数据获取申请趋势每年数量 end*/
        /* 根据publicationYearMap数据获取公开趋势每年数量 start*/
        let publicationData = [];
        for (let key in val.publicationYearMap) {
          publicationData.push(val.publicationYearMap[key]);
        }
        /* 根据publicationYearMap数据获取公开趋势每年数量 end*/
        /* 根据grantYearMap数据获取公开趋势每年数量 start*/
        let grantData = [];
        for (let key in val.grantYearMap) {
          grantData.push(val.grantYearMap[key]);
        }
        /* 根据grantYearMap数据获取公开趋势每年数量 end*/
        /* 根据applicationYearMap数据获取年份数据 start*/
        let yearData = [];
        for (let key in val.applicationYearMap) {
          yearData.push(key);
        }
        /* 根据applicationYearMap数据获取年份数据 end*/
        this.options = {
          title: {
            text: "近二十年专利申请、公开、授权趋势图",
            x: "center"
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            y: "bottom",
            x: "right",
            data: ["专利申请趋势", "专利公开趋势", "专利授权趋势"]
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            top: "10%",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: yearData
          },
          yAxis: {
            type: "value"
          },
          series: [
            {
              name: "专利申请趋势",
              type: "line",
              stack: "总量",
              data: applicationData
            },
            {
              name: "专利公开趋势",
              type: "line",
              stack: "总量",
              data: publicationData
            },
            {
              name: "专利授权趋势",
              type: "line",
              stack: "总量",
              data: grantData
            }
          ]
        };
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped></style>
